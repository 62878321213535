export const routes = {
  index: '/',
  login: '/login',
  sgidLoginVerify: 'sgid/login-verify',
  dashboard: '/dashboard',
  requests: {
    create: '/requests/create',
    view: '/requests/:requestId',
    edit: '/requests/:requestId/edit',
    workflow: '/requests/create/:requestType/:stepId',
  },
  health: '/health',
  error: '/error',
}
