import { queryClient } from '~/app/App'

import { axiosInstance } from '.'

export const whoami = async () => {
  const response = await axiosInstance.get('/whoami')
  return response.data as { userId: string }
}

export const logout = async () => {
  await axiosInstance.post('/logout')
  await queryClient.invalidateQueries()
}

export const login = async (email: string) => {
  return await axiosInstance.post('/login', { email })
}

export const verifyOtp = async (email: string, token: string) => {
  return await axiosInstance.post('/verify-otp', { email, token })
}

export const getSgidAuthUrl = async (from: string) => {
  return await axiosInstance.post('/sgid/getAuthUrl', { from })
}

export const sgidCallback = async (code: string) => {
  return await axiosInstance.post('/sgid/callback', { code })
}

export const sgidProfileLogin = async (email: string, id: string) => {
  return await axiosInstance.post('/sgid/login', { email, id })
}
