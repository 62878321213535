import { createContext, ReactNode, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { logout as logoutapi } from '~/api'

import { useLocalStorage } from '~lib/storage'
import { routes } from '~constants/routes'

import { useAuthQuery } from '~features/auth'
import { STORAGE_LOGGED_IN_KEY } from '~features/auth'

type AuthContextProps = {
  isAuthenticated?: boolean
  setIsLoggedIn: (val: boolean) => void
  userId?: string
  authLogout: () => void
}
export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
)

/**
 * Provider component that wraps your app and makes auth object available to any
 * child component that calls `useAuth()`.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

/**
 * Hook for components nested in ProvideAuth component to get the current auth object.
 */
export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider component`)
  }
  return context
}

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage(
    STORAGE_LOGGED_IN_KEY,
    false,
  )
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const { data: user } = useAuthQuery({
    enabled: isLoggedIn,
  })

  const logout = useCallback(async () => {
    setIsLoggedIn(false)
    await logoutapi()
    navigate(routes.login)
    queryClient.clear()
  }, [navigate, queryClient, setIsLoggedIn])

  return {
    isAuthenticated: isLoggedIn,
    setIsLoggedIn: setIsLoggedIn,
    userId: user?.userId,
    authLogout: logout,
  }
}
