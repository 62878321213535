import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import {
  RestrictedGovtMasthead,
  ThemeProvider,
} from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { theme } from '~/theme'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme} resetCSS>
      <RestrictedGovtMasthead />
    </ThemeProvider>
    <AppRouter />
  </QueryClientProvider>
)
