import { useQuery } from '@tanstack/react-query'

import { sgidCallback, whoami } from '~/api'
type UseAuthProps = {
  staleTime?: number
  enabled: boolean | undefined
}
export const authKeys = {
  currentUser: ['currentUser'] as const,
  sgidAuthUrl: ['sgidAuthUrl'] as const,
  sgidCallback: ['sgidCallback'] as const,
  sgidLogin: ['sgidLogin'] as const,
}
export const useAuthQuery = ({ staleTime = 600000, enabled }: UseAuthProps) =>
  useQuery([authKeys.currentUser], () => whoami(), {
    staleTime,
    enabled,
  })

export const useGetSgidCallbackQuery = (code: string) =>
  useQuery({
    queryKey: [authKeys.sgidCallback],
    queryFn: async () => {
      const response = await sgidCallback(code)
      return response.data as { email: string; sessionId: string }[]
    },
  })
