import axios from 'axios'

import { STORAGE_LOGGED_IN_KEY } from '~features/auth'

export * from './auth'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
export const axiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
  timeout: 10000,
  withCredentials: true,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error: { response: unknown }) => {
    const response = error.response as { status: number; data: unknown }
    if (response.status === 401) {
      window.localStorage.removeItem(STORAGE_LOGGED_IN_KEY)
      window.dispatchEvent(new Event('local-storage'))
      throw new Error('Unauthorized')
    }

    const data = response.data as { message: string }
    return Promise.reject({
      message: data?.message,
      status: response.status,
    })
  },
)
