import { PropsWithChildren, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { AuthProvider } from '~lib/authProvider'
import { routes } from '~constants/routes'
import { lazyImport } from '~utils/lazyImport'

const { LoginRoutes } = lazyImport(
  () => import('~features/auth/routes'),
  'LoginRoutes',
)

const { LoginVerifyRoutes } = lazyImport(
  () => import('~features/auth/routes'),
  'LoginVerifyRoutes',
)

const { DashboardRoutes } = lazyImport(
  () => import('~features/dashboard/routes'),
  'DashboardRoutes',
)

const { CreateRequestRoutes } = lazyImport(
  () => import('~features/request/routes'),
  'CreateRequestRoutes',
)

const { ViewRequestRoutes } = lazyImport(
  () => import('~features/request/routes'),
  'ViewRequestRoutes',
)

const { EditRequestRoutes } = lazyImport(
  () => import('~features/request/routes'),
  'EditRequestRoutes',
)

const { ErrorRoutes } = lazyImport(() => import('./ErrorPage'), 'ErrorRoutes')

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

const returnRouteComponent = (route: string, element: React.ReactElement) => {
  return <Route path={route} element={<AuthProvider>{element}</AuthProvider>} />
}

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <Router>
        <Routes>
          {returnRouteComponent(routes.error, <ErrorRoutes />)}
          {returnRouteComponent(routes.login, <LoginRoutes />)}
          {returnRouteComponent(routes.sgidLoginVerify, <LoginVerifyRoutes />)}
          {returnRouteComponent(routes.index, <DashboardRoutes />)}
          {returnRouteComponent(routes.dashboard, <DashboardRoutes />)}
          {returnRouteComponent(
            routes.requests.create,
            <CreateRequestRoutes />,
          )}
          {returnRouteComponent(routes.requests.edit, <EditRequestRoutes />)}
          {returnRouteComponent(routes.requests.view, <ViewRequestRoutes />)}
        </Routes>
      </Router>
    </WithSuspense>
  )
}
